import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getInstagramLink, getFacebookLink } from 'redux/selectors'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { NextImage } from 'components/shared/NextImage'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { SmartLink } from 'components/shared/SmartLink'
import { HorizontalDirectionArrowIcon } from '../icons'
import { SocialLink } from 'layouts/Footer/SocialLinks'
import { getPrismicImage } from 'helpers/ImageManager'

const StyledSmartLink = styled(SmartLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledDivider = styled(Divider)`
  transform: scaleY(0.5);
  margin: auto;

  ${(props) => props.theme.media.tablet`
    transform: scaleX(0.5);
  `}
`

const commonPropTypes = {
  foreground_logo: PropTypes.string,
  foreground_logo_alt: PropTypes.string,
  facebook_url: PropTypes.string,
  instagram_url: PropTypes.string,
  twitter_url: PropTypes.string,
  wechat_url: PropTypes.string,
  linkedin_url: PropTypes.string,
  tiktok_url: PropTypes.string,
  partner_site_url: PropTypes.string,
}

function BrandedBlock({
  foregroundLogo,
  foregroundLogoAlt,
  backgroundLogo,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  wechatUrl,
  linkedinUrl,
  tiktokUrl,
  partnerSiteUrl,
}) {
  const { t } = useTranslation()
  const socialLinkEntries = useMemo(
    () =>
      Object.entries({
        facebook: facebookUrl,
        twitter: twitterUrl,
        instagram: instagramUrl,
        wechat: wechatUrl,
        linkedin: linkedinUrl,
        tiktok: tiktokUrl,
      }).filter(([, value]) => Boolean(value)),
    [facebookUrl, instagramUrl, linkedinUrl, tiktokUrl, twitterUrl, wechatUrl],
  )

  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap='56px'
      color='white'
      backgroundLogo={backgroundLogo}
      py='36px'
    >
      <Box position='relative'>
        <NextImage src={foregroundLogo} alt={foregroundLogoAlt} width={80} height={80} />
      </Box>
      <div>
        <Grid templateColumns={`repeat(${socialLinkEntries.length}, 1fr)`} gap='28px' mb='16px'>
          {socialLinkEntries.map(([type, href]) => (
            <SocialLink key={type} type={type} href={href} />
          ))}
        </Grid>
        <Flex flexDirection='row' gap='8px' alignItems='center' justifyContent='center'>
          <StyledSmartLink href={partnerSiteUrl}>{t('CobrandedFooter::visit website')}</StyledSmartLink>
          <HorizontalDirectionArrowIcon direction='forward' size='14px' />
        </Flex>
      </div>
    </Flex>
  )
}

BrandedBlock.propTypes = commonPropTypes.isRequired

export function CobrandedFooter({
  staticFields: {
    foreground_logo: foregroundLogo,
    foreground_logo_alt: foregroundLogoAlt,
    background_logo: backgroundLogo,
    facebook_url: facebookUrl,
    instagram_url: instagramUrl,
    twitter_url: twitterUrl,
    wechat_url: wechatUrl,
    linkedin_url: linkedinUrl,
    tiktok_url: tiktokUrl,
    partner_site_url: partnerSiteUrl,
  },
}) {
  const isTabletOrGreater = useMediaQuery('tablet')
  const { eatwithBackgroundLogoUrl, brandBackgroundLogoUrl } = useMemo(
    () => ({
      eatwithBackgroundLogoUrl: getPrismicImage(1530630, '290x290'),
      brandBackgroundLogoUrl: getPrismicImage(parseInt(backgroundLogo, 10), '290x290'),
    }),
    [backgroundLogo],
  )
  const instagramLink = useSelector(getInstagramLink)
  const facebookLink = useSelector(getFacebookLink)

  return (
    <Grid
      as='footer'
      width='100%'
      gridTemplateColumns={{ default: '1fr', tablet: '1fr 1px 1fr' }}
      gridTemplateRows={{ default: '1fr 1px 1fr', tablet: '1fr' }}
      bg='darkBlue'
      gap={{ default: '36px', tablet: '0' }}
    >
      <Box background={{ tablet: `url(${eatwithBackgroundLogoUrl}) no-repeat -120px -70px` }}>
        <BrandedBlock
          foregroundLogo={1530629}
          foregroundLogoAlt='Eatwith logo'
          facebookUrl={facebookLink}
          instagramUrl={instagramLink}
          twitterUrl='https://twitter.com/Eatwith'
          wechatUrl='https://eatwith.net.cn'
          tiktokUrl='https://www.tiktok.com/@eatwithlocals'
          linkedinUrl='https://www.linkedin.com/company/eatwith/'
          partnerSiteUrl='https://www.eatwith.com'
        />
      </Box>

      <StyledDivider
        orientation={isTabletOrGreater ? 'vertical' : 'horizontal'}
        width={{ default: '80%', tablet: '100%' }}
        height={{ default: '100%', tablet: '80%' }}
      />

      <Box
        background={{
          tablet: `url(${brandBackgroundLogoUrl}) no-repeat calc(100% + 120px) -70px`,
        }}
      >
        <BrandedBlock
          foregroundLogo={parseInt(foregroundLogo, 10)}
          foregroundLogoAlt={foregroundLogoAlt}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          twitterUrl={twitterUrl}
          wechatUrl={wechatUrl}
          linkedinUrl={linkedinUrl}
          tiktokUrl={tiktokUrl}
          partnerSiteUrl={partnerSiteUrl}
        />
      </Box>
    </Grid>
  )
}

const cobrandedFooterPropTypes = { ...commonPropTypes, background_logo: PropTypes.string }
CobrandedFooter.propTypes = {
  staticFields: PropTypes.shape(cobrandedFooterPropTypes).isRequired,
}
